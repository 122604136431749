import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/outline';
import React from 'react';

export default function Step2(props) {

  const year = (new Date()).getFullYear();
  const years = Array.from(new Array(20), (val, index) => year - index);

  return (
    <div id="QuizStep2">
      <label htmlFor={`cancer_year_diagnosis`} className="mb-5 block text-xl md:text-2xl font-bold text-black">
        What year were you or a loved one diagnosed with {props.cancer_type.includes("Not listed") || props.cancer_type.includes("Undiagnosed") ? "your illness": props.cancer_type.join("/")}?
      </label>
      <div>
        <select
          id="cancer_diagnosis_year"
          name="cancer_diagnosis_year"
          autoComplete="year"
          value={props.cancer_diagnosis_year}
          onChange={(event) => props.updateFieldValue("cancer_diagnosis_year", event.target.value)}
          className="mt-1 block text-gray-700 w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm"
        >
          {years.map((year, index) => {
            return <option className="text-gray-700" key={`year${index}`} value={year}>{year}</option>
          })
          }
        </select>
      </div>
      <div className="grid grid-cols-2 gap-2 mt-10 text-right">
        <button
          onClick={() => props.prevStep(2)}
          className="
                            inline-flex 
                            items-center 
                            justify-center
                            py-1
                            px-2 
                            w-full
                            shadow-sm 
                            uppercase
                            text-medium
                            font-medium 
                            tracking-widest
                            text-black border border-black hover:text-white hover:bg-gray-900
                            focus:outline-none 
                            focus:ring-2 
                            focus:ring-offset-2 
                            focus:ring-blue-500"
        >
          <ArrowLeftIcon className='mr-2 h-4 w-4' /> Back
        </button>
        <button
          onClick={() => props.nextStep(2)}
          className="
                            inline-flex 
                            items-center 
                            justify-center
                            py-1
                            px-2 
                            w-full
                            shadow-sm 
                            uppercase
                            text-medium
                            font-medium 
                            tracking-widest
                            text-black border border-black hover:text-white hover:bg-gray-900
                            focus:outline-none 
                            focus:ring-2 
                            focus:ring-offset-2 
                            focus:ring-blue-500"
        >
          Next <ArrowRightIcon className="ml-2 h-4 w-4" />
        </button>
      </div>
    </div>
  );
}
