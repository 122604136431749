import React, { useState } from 'react'
import { XCircleIcon, ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/outline';
import { supabase } from '../../../utils/supabaseClient';
import styled, { keyframes } from 'styled-components';
import { slideInUp } from 'react-animations';
import Image from 'next/image'

const slideInUpAnimation = keyframes`${slideInUp}`;

const SlideInUpDiv = styled.div`
  animation: 1s ${slideInUpAnimation};
`;


export default function Step1(props) {


    const [lawsuits, setLawsuits] = useState(props.lawsuits);
    const [injuries, setInjuries] = useState([])
    const [selectedInjuries, setSelectedInjuries] = useState([])
    const [product, setProduct] = useState("")

    // console.log("selected injuries", selectedInjuries)


    const [stepOneB, showStepOneB] = useState(false)
    const [introStep, showIntroStep] = useState(true)
    const [errorStepOneB, setErrorStepOneB] = useState(false)

    const updateFieldValue = (name, value, name2) => {
        var arr = selectedInjuries
        let newArr = [...arr];
        if (value === false) {
            console.log("remove value", name2)
            var filtered = newArr.filter(function (value, index, arr) {
                return value != name2;
            });
            setSelectedInjuries(filtered)
        } else {
            newArr.push(name2);
            setSelectedInjuries(newArr)
        }
    }

    const handleStepOneA = (lawsuit, injuries) => {
    props.updateFieldValue("lawsuit", `${lawsuit} Lawsuit`)
        showStepOneB(true)
        setInjuries(injuries)
        setProduct(lawsuit)
    }


    const handleNextStep = () => {
        if (selectedInjuries.length) {
            props.updateFieldValue("cancer_type", selectedInjuries)
            props.nextStep(1)

        }
        else {
            setErrorStepOneB(true)
        }
    }

    const handleStepOneBack = () => {
        showStepOneB(false)
        setInjuries([])
    }


    // console.log(lawsuits)

    return (
        <div id="QuizStep1">
            <div>
                {!stepOneB ?
                    !introStep ?
                        <div>
                            <label htmlFor={`lawsuit`} className="mb-5 block text-xl md:text-2xl font-bold text-black">
                                Were you or a loved one effected by or used any of the following items?
                            </label>
                            <div className='grid grid-cols-12 gap-4'>
                                {lawsuits.map((lawsuit) =>
                                    <div key={lawsuit.id}
                                        onClick={() => handleStepOneA(lawsuit.title, lawsuit.injuries)}
                                        className="col-span-12 md:col-span-6 lg:col-span-6 h-auto flex items-start justify-center text-center bg-white cursor-pointer rounded-lg shadow p-8 border-2 border-white hover:bg-teal-100 hover:border-teal-600">
                                        <div className="text-lg -mt-1">
                                            <label htmlFor={lawsuit.title} className="font-bold text-gray-700">
                                                <div className='relative flex justify-center mb-2 rounded-sm items-center w-60 h-40 min-h-40 bg-gray-200'>
                                                    {lawsuit.product_image && <Image
                                                        src={lawsuit.product_image}
                                                        alt={lawsuit.title}
                                                        layout="fill"
                                                        objectFit="cover"
                                                        className="z-0 drop-shadow w-full rounded-sm h-full object-center object-cover"
                                                    />}
                                                </div>
                                                {lawsuit.title}
                                            </label>
                                            {/* <p className="text-gray-500 text-sm italic mt-4 mb-0">{lawsuit.products}</p> */}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        :
                        <div className="max-w-2xl mx-auto text-center p-4 md:p-4">
                            <h1 className="mt-2 text-3xl leading-8 font-serif tracking-tight font-black text-black sm:text-4xl">
                                Free Lawsuit Eligibility Quiz
                            </h1>
                            <h2 className="font-normal mt-4 max-w-2xl text-sm md:text-xl text-gray-700 lg:mx-auto">
                                Our team partners with law firms across the country to connect you with the resources you need to move forward with a case,  of charge.*
                            </h2>
                            <p className="mt-4 max-w-2xl text-xs md:text-lg text-gray-700 lg:mx-auto">
                                Please answer the following preliminary questions so we can better connect you with a qualified law firm.
                            </p>

                            <button
                                onClick={() => showIntroStep(false)}
                                className='transition 
                                ease-in-out 
                                delay-150 
                                text-white
                                text-lg
                                md:text-xl
                                cursor-pointer
                                w-full
                                group
                                mb-2
                                hover:-translate-y-1 
                                hover:drop-shadow-xl
                                bg-teal-500
                                hover:bg-teal-600
                                duration-300 
                                px-6 py-4
                                drop-shadow-lg 
                                tracking-widest
                                rounded-sm 
                                flex 
                                font-extrabold 
                                uppercase 
                                items-center 
                                justify-center'>
                                Start Quiz<ArrowRightIcon className='transition transform  ease-out group-hover:ease-in motion-reduce:transition-none motion-reduce:hover:transform-none group-hover:translate-x-2 ml-2 h-5 w-5' />
                            </button>
                            <p className='mt-5 text-xs text-gray-600 italic'>
                                *This website and its content may be deemed attorney advertising. TheLegalCanary.com partners with law firms within the United States. The information on this website is not medical advice and is not a substitute for professional medical advice, diagnosis or treatment. The information on this website does not constitute as legal advice and is not a substitute for professional legal advice. Please seek the advice of medical and legal professionals before making health care and legal decisions. No attorney-client relationship is formed by your use or communication through this website. Choosing a lawyer is an important decision and should not be based solely on advertisements.
                                TheLegalCanary.com is not a law firm.
                            </p>
                        </div>
                    :
                    <div>
                        {/* <SlideInUpDiv className='absolute italic text-sm top-3 text-teal-700 font-semibold'>Got it! Good to know!</SlideInUpDiv> */}
                        <label htmlFor={`lawsuit`} className="mb-5 block text-xl md:text-2xl font-bold text-black">
                            {product === "Acetaminophen" ?
                                `Did you or a loved one take ${product} while pregnant? Did the child develop any of the following?` :
                                product == "Camp Lejeune" ? `Were you or a loved one diagnosed with any of the following after drinking the water at ${product}?` :
                                    `Were you or a loved one impacted by ${product}?`
                            }
                        </label>
                        <p className='text-gray-900 mb-10 uppercase tracking-widest'>(select all that apply)</p>
                        <div>
                            {injuries.map((injury, index) =>
                                <div key={index} className="flex items-center mb-3">
                                    <div className="flex items-center h-5">
                                        <input
                                            id={`${injury}${props.form}`}
                                            name={injury}
                                            type="checkbox"
                                            className={`bg-white focus:ring-teal-500 h-5 w-5 text-teal-600 ${errorStepOneB ? 'border-red-500' : 'border-gray-300'} rounded`}
                                            onChange={(e) => updateFieldValue('cancer_type', e.target.checked, e.target.name)}
                                            checked={selectedInjuries.includes(injury)}
                                            value={selectedInjuries.includes(injury)}
                                        />
                                    </div>
                                    <div className="ml-3 text-base text-left">
                                        <label htmlFor={`${injury}${props.form}`} className="font-normal text-gray-700">
                                            {injury}
                                        </label>
                                    </div>
                                </div>
                            )}
                            <div className="flex items-center mb-3">
                                <div className="flex items-center h-5">
                                    <input
                                        id={`notListed${props.form}`}
                                        name="Not listed"
                                        type="checkbox"
                                        className={`bg-white focus:ring-teal-500 h-5 w-5 text-teal-600 ${errorStepOneB ? 'border-red-500' : 'border-gray-300'} rounded`}
                                        onChange={(e) => updateFieldValue('cancer_type', e.target.checked, e.target.name)}
                                        checked={selectedInjuries.includes("Not listed")}
                                        value={selectedInjuries.includes("Not listed")}
                                    />
                                </div>
                                <div className="ml-3 text-base">
                                    <label htmlFor={`notListed${props.form}`} className="font-normal text-gray-700">
                                        Not listed
                                    </label>
                                </div>
                            </div>
                            <div className="flex items-center mb-3">
                                <div className="flex items-center h-5">
                                    <input
                                        id={`Undiagnosed${props.form}`}
                                        name="Undiagnosed"
                                        type="checkbox"
                                        className={`bg-white focus:ring-teal-500 h-5 w-5 text-teal-600 ${errorStepOneB ? 'border-red-500' : 'border-gray-300'} rounded`}
                                        onChange={(e) => updateFieldValue('cancer_type', e.target.checked, e.target.name)}
                                        checked={selectedInjuries.includes("Undiagnosed")}
                                        value={selectedInjuries.includes("Undiagnosed")}
                                    />
                                </div>
                                <div className="ml-3 text-base">
                                    <label htmlFor={`Undiagnosed${props.form}`} className="font-normal text-gray-700">
                                        Undiagnosed
                                    </label>
                                </div>
                            </div>
                            {
                                errorStepOneB &&
                                <p className="mt-2 mb-0 text-xs text-red-500 flex col-span-12">
                                    <XCircleIcon className="h-4 w-4 mr-1" /> Please select a value to proceed.
                                </p>
                            }
                        </div>
                        <div className="grid grid-cols-2 gap-2 mt-10 text-right">
                            <button
                                onClick={() => handleStepOneBack()}
                                className="
                            inline-flex 
                            items-center 
                            justify-center
                            py-1
                            px-2 
                            w-full
                            shadow-sm 
                            uppercase
                            text-medium
                            font-medium 
                            tracking-widest
                            text-black border border-black hover:text-white hover:bg-gray-900
                            focus:outline-none 
                            focus:ring-2 
                            focus:ring-offset-2 
                            focus:ring-blue-500"
                            >
                                <ArrowLeftIcon className='mr-2 h-4 w-4' /> Back
                            </button>
                            <button
                                onClick={() => handleNextStep()}
                                className="
                            inline-flex 
                            items-center 
                            justify-center
                            py-1
                            px-2 
                            w-full
                            shadow-sm 
                            uppercase
                            text-medium
                            font-medium 
                            tracking-widest
                            text-black border border-black hover:text-white hover:bg-gray-900
                            focus:outline-none 
                            focus:ring-2 
                            focus:ring-offset-2 
                            focus:ring-blue-500"
                            >
                                Next <ArrowRightIcon className="ml-2 h-4 w-4" />
                            </button>
                        </div>
                    </div>
                }
            </div>
        </div>
    );

}

